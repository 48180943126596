import catalogsLang from "@/langs/configuration/catalogs";

const configurationLang = {
  en: {
    configuration: "Configuration",
    transactionsVoucher: "Transaction voucher",
    start: "Start",
    end: "End",
    availableVouchers: "Available vouchers",
    dateExpired: "Date expired",
    reservedVouchers: "Reserved vouchers",
    voucherNumber: "Voucher number",
    approved: "Approved",
    IDPlanificationExpenseRequest: "ID planification expense request",
    rejectRequest: "Reject request",
    rejectRequestConfirmation: "Are you sure you want to reject the request?",
    approveRequest: "Approve request",
    approveRequestConfirmation: "Are you sure you want to approve the request?",
    ...catalogsLang.en,
  },
  es: {
    configuration: "Configuración",
    transactionsVoucher: "Transacciones de voucher",
    start: "Inicio",
    end: "Fin",
    availableVouchers: "Vales disponibles",
    dateExpired: "Fecha vencimiento",
    reservedVouchers: "Vales reservados",
    voucherNumber: "Número de vale",
    approved: "Aprobado",
    IDPlanificationExpenseRequest: "ID compra",
    rejectRequest: "Rechazar solicitud",
    rejectRequestConfirmation: "Está seguro de rechazar la solicitud?",
    approveRequest: "Aprobar solicitud",
    approveRequestConfirmation: "Está seguro de aprobar la solicitud?",
    ...catalogsLang.es,
  },
};

export default configurationLang;
