const rolesLang = {
  en: {
    roles: "Roles",
    slug: "Slug",
    permissions: "Permissions",
    savePermissions: "Save permissions",
    savedPermissions: "The permissions has saved correctly",
    role_insert_succesful: "The role has saved correctly",
    role_updated_successful: "The role has updated correctly",
  },
  es: {
    roles: "Roles",
    slug: "Slug",
    permissions: "Permisos",
    savePermissions: "Guardar permisos",
    savedPermissions: "Los permisos se han guardado correctamente",
    role_insert_succesful: "El rol se ha guardado correctamente",
    role_updated_successful: "El rol se ha actualizado correctamente",
  },
};

export default rolesLang;
