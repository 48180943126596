const documentationLang = {
  en: {
    institutionalDocumentation: "Institutional documentation",
    attachment: "Attachment",
    department: "Department",
    city: "City",
    provider_insert_succesful: "The provider has saved correctly",
    provider_updated_successful: "The provider has updated correctly",
  },
  es: {
    institutionalDocumentation: "Documentación institucional",
    attachment: "Adjunto",
    department: "Departamento",
    city: "Ciudad",
    provider_insert_succesful: "El proveedor se ha guardado correctamente",
    provider_updated_successful: "El proveedor se ha actualizado correctamente",
  },
};

export default documentationLang;
