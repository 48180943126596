const populationActivitiesLang = {
  en: {
    activities: "Activities",
    populationActivities: "Group activities",
    workshop: "Workshop",
    informationActivity: "Information activity",
    participants: "Participants",
    photography: "Photography",
    objective: "Objective",
    logistics: "Logistics",
    addedBy: "Added by",
    onDate: "On date",
    growth: "Growth",
    evaluation: "Evaluation",
    result: "Result",
    supportFor: "Support for",
    addParticipant: "Add participant",
    group_activity_insert_succesful: "The activity has been saved successful",
    group_activity_updated_successful:
      "The activity has been updated successful",
    group_activity_person_insert_succesful:
      "The participant has been added successful",
    group_activity_person_deleted_successful:
      "Disassociated participant successfully",
    group_activity_photo_deleted_successful:
      "The photo has been deleted successfully",
  },
  es: {
    activities: "Actividades",
    populationActivities: "Actividades grupales",
    workshop: "Taller",
    informationActivity: "Información de actividad",
    participants: "Participantes",
    photography: "Fotografía",
    objective: "Objetivo",
    logistics: "Logística",
    addedBy: "Agregado por",
    onDate: "De fecha",
    growth: "Desarrollo",
    evaluation: "Evaluación",
    result: "Resultado",
    supportFor: "Apoyo",
    addParticipant: "Agregar participante",
    group_activity_insert_succesful:
      "La actividad se ha guardado correctamente",
    group_activity_updated_successful:
      "La actividad se ha actualizado correctamente",
    group_activity_person_insert_succesful:
      "El participante se ha agregado correctamente",
    group_activity_person_deleted_successful:
      "El participante se ha desasociado correctamente",
    group_activity_photo_deleted_successful:
      "La foto fue eliminada correctamente",
  },
};

export default populationActivitiesLang;
