const administrativeActivitiesLang = {
  en: {
    activities: "Activities",
    administrativeActivities: "Administrative activities",
    workshop: "Workshop",
    calendar: "Calendar",
    identification: "Identification",
    operative: "Operative",
    director: "Director",
    observations: "Observations",
    manager: "Manager",
    questionsSerie: "Question serie",
    collaborator: "Collaborator",
    comment: "Comment",
    questions: "Questions",
    collaboratorData: "Collaborator data",
    reviewSubmit: "Review submit",
    typeEvaluation: "Type evaluation",
    relatedComments: "Related comments",
    performanceEvaluation: "Performance evaluation",
    addActivity: "Add activity",
    informationActivity: "Information activity",
    participants: "Participants",
    photography: "Photography",
    objective: "Objective",
    logistics: "Logistics",
    growth: "Growth",
    evaluation: "Evaluation",
    result: "Result",
    review: "Review",
    dateStart: "Date start",
    dateEnd: "Date end",
    title: "Title",
    selectUser: "Select user",
    activityLog: "Activity Log",
    supportFor: "Support for",
    addParticipant: "Add participant",
    group_activity_insert_succesful: "The activity has been saved successful",
    group_activity_updated_successful:
      "The activity has been updated successful",
    group_activity_person_insert_succesful:
      "The participant has been added successful",
    group_activity_person_deleted_successful:
      "Disassociated participant successfully",
    group_activity_photo_deleted_successful:
      "The photo has been deleted successfully",
  },
  es: {
    activities: "Actividades",
    administrativeActivities: "Actividades administrativas",
    workshop: "Taller",
    calendar: "Calendario",
    identification: "Identificación",
    addActivity: "Añadir actividad",
    operative: "Operativo",
    director: "Director",
    collaboratorData: "Información de colaborador",
    questionsSerie: "Serie de preguntas",
    questions: "Preguntas",
    observations: "Observaciones",
    collaborator: "Colaborador",
    manager: "Gerente",
    comment: "Comentario",
    relatedComments: "Comentarios relacionados",
    reviewSubmit: "Enviar formulario",
    typeEvaluation: "Tipo de evaluación",
    performanceEvaluation: "Evaluación de desempeño",
    informationActivity: "Información de actividad",
    participants: "Participantes",
    photography: "Fotografía",
    objective: "Objetivo",
    logistics: "Logística",
    growth: "Desarrollo",
    evaluation: "Evaluación",
    result: "Resultado",
    supportFor: "Apoyo",
    review: "Revisar",
    dateStart: "Fecha inicio",
    dateEnd: "Fecha fin",
    title: "Título",
    selectUser: "Seleccione usuario",
    activityLog: "Registro de actividades",
    addParticipant: "Agregar participante",
    group_activity_insert_succesful:
      "La actividad se ha guardado correctamente",
    group_activity_updated_successful:
      "La actividad se ha actualizado correctamente",
    group_activity_person_insert_succesful:
      "El participante se ha agregado correctamente",
    group_activity_person_deleted_successful:
      "El participante se ha desasociado correctamente",
    group_activity_photo_deleted_successful:
      "La foto fue eliminada correctamente",
  },
};

export default administrativeActivitiesLang;
