const catalogsLang = {
  en: {
    catalogs: "Catalogs",
    expenseTypes: "Expense types",
    tasks: "Tasks",
    theme_insert_succesful: "The theme has been saved correctly",
    // AGE GROUP
    age_group_updated_successful: "The age group has been updated correctly",
    // ACADEMIC
    academic_degree_insert_succesful:
      "The academic degree has been saved correctly",
    academic_degree_deleted_successful:
      "The academic degree has been deleted correctly",
  },
  es: {
    catalogs: "Catálogos",
    expenseTypes: "Tipos de gasto",
    tasks: "Tareas",
    theme_insert_succesful: "El taller se ha guardado correctamente",
    // AGE GROUP
    age_group_updated_successful:
      "El grupo etario ha sido actualizado correctamente",
    // ACADEMIC
    academic_degree_insert_succesful:
      "El grado académico se ha guardado correctamente",
    academic_degree_deleted_successful:
      "El grado académico se ha eliminado correctamente",
  },
};

export default catalogsLang;
