import administrativeActivitiesLang from "@/langs/administrative/administrativeActivities";

const administrativeLang = {
  en: {
    administrative: "Administrative",
    populationServed: "Population served",
    listPopulation: "Population list",
    addPopulation: "Add person",
    createPerson: "Create person",
    names: "Names",
    lastNames: "Last names",
    idPerson: "ID person",
    alias: "Nickname",
    age: "Age",
    civilStatus: "Civil status",
    sex: "Sex",
    sexualOrientation: "Sexual orientation",
    nationality: "Nationality",
    ethnicGroup: "Ethnic group",
    ageGroup: "Age group",
    academicDegree: "Academic degree",
    knowsRead: "Knows read",
    knowsWrite: "Knows write",
    personWithDisability: "Person with Disability?",
    explainYourAnswer: "Explain your answer",
    areYouPregnant: "Are you pregnant?",
    guatemalan: "Guatemalan",
    foreign: "Foreign",
    country: "Country",
    state: "State",
    town: "Town",
    community: "Community",
    weight: "Weight (lbs)",
    height: "Height (mts)",
    phoneHouse: "Phone house",
    phoneEmergency: "Phone house",
    bloodType: "Blood type",
    personalInformation: "Personal information",
    entranceToPrograms: "Entrance to programs",
    careReceived: "Care received",
    linkedPeople: "Linked people",
    perpetrator: "Perpetrator",
    numberOfExternalFiles: "Number of external files",
    dateAdmission: "Date of admission",
    reasonAdmission: "Reason of admission",
    referrer: "Referrer",
    court: "Court",
    thematic: "Thematic",
    thematicDivision: "Thematic division",
    logicFrame: "Logic frame",
    task: "Task",
    rescuedPerson: "Rescued person?",
    report: "Report",
    right: "Right",
    rightDivision: "Right division",
    assignPermission: "Assign permission",
    assignRight: "Assign right",
    assignCareReceived: "Assign care received",
    addCareReceived: "Add care received",
    activityDate: "Activity date",
    person_insert_succesful: "The person has saved correctly",
    person_updated_successful: "The person has updated correctly",
    addProgram: "Add program",
    lastResidence: "Last residence",
    enterLinkedPerson: "Enter linked person",
    dateDeparture: "Date departure",
    admin: "Admin",
    canRead: "Can read",
    canWrite: "Can write",
    addUser: "Add user",
    letOut: "Let out",
    linkPerson: "Link person",
    bond: "Bond",
    addOffender: "Add offender",
    numberOfExternalFilesSimbol: "# record",
    noteDeparture: "Departure note",
    seePermissions: "See permissions",
    requestPermission: "Request permission",
    removeBond: "Remove bond",
    person_activity_insert_succesful:
      "The person's activity has been added successfully",
    person_activity_updated_successful:
      "The person's activity has been updated successfully",
    person_victimizer_insert_succesful:
      "The offender has been added successfully",
    person_victimizer_deleted_successful:
      "The offender has been deleted successfully",
    person_activity_deleted_successful:
      "The person's activity has been deleted successfully",
    person_asignation_insert_succesful: "The user has been added successfully",
    person_retrieved_successful: "The access request was sent successfully",
    person_asignation_updated_successful:
      "The access request was sent successfully",
    sureDeleteActivity: "Are you sure to delete the activity?",
    sureDeleteBond: "Are you sure to delete the bond?",
    sureDeletePerpetrator: "Are you sure to delete the perpetrator?",
    person_family_bond_deleted_successful:
      "The bond has been deleted successfully",
    ...administrativeActivitiesLang.en,
  },
  es: {
    administrative: "Administrativo",
    populationServed: "Población atendida",
    listPopulation: "Listado de población",
    addPopulation: "Agregar persona",
    createPerson: "Crear persona",
    names: "Nombres",
    lastNames: "Apellidos",
    idPerson: "ID persona",
    alias: "Alias",
    age: "Edad",
    civilStatus: "Estado civil",
    sex: "Sexo",
    sexualOrientation: "Orientación sexual",
    nationality: "Nacionalidad",
    ethnicGroup: "Grupo étnico",
    ageGroup: "Grupo etario",
    academicDegree: "Grado académico",
    knowsRead: "Sabe leer",
    knowsWrite: "Sabe escribir",
    personWithDisability: "¿Persona con discapacidad?",
    explainYourAnswer: "Especifique su respuesta",
    areYouPregnant: "¿Se encuentra en estado de gestación?",
    guatemalan: "Guatemalteco",
    foreign: "Extranjero",
    country: "País",
    state: "Departamento",
    town: "Municipio",
    community: "Comunidad",
    weight: "Peso (lbs)",
    height: "Altura (mts)",
    phoneHouse: "Teléfono de casa",
    phoneEmergency: "Teléfono de emergencia",
    bloodType: "Tipo de sangre",
    personalInformation: "Información personal",
    entranceToPrograms: "Ingreso a programas",
    careReceived: "Atenciones recibidas",
    linkedPeople: "Personas vinculadas",
    perpetrator: "Victimario",
    numberOfExternalFiles: "Número de expedientes externo",
    dateAdmission: "Fecha de ingreso",
    reasonAdmission: "Motivo de ingreso",
    referrer: "Referente",
    court: "Juzgado",
    thematic: "Temática",
    thematicDivision: "División temática",
    logicFrame: "Marco lógico",
    task: "Tarea",
    rescuedPerson: "¿Persona rescatada?",
    report: "Informe",
    right: "Derecho",
    rightDivision: "División derecho",
    assignPermission: "Asignar permiso",
    assignRight: "Asignar derecho",
    assignCareReceived: "Asignar atención recibida",
    addCareReceived: "Agregar atención recibida",
    activityDate: "Fecha de actividad",
    person_insert_succesful: "La persona se ha guardado correctamente",
    person_updated_successful: "La persona se ha actualizado correctamente",
    addProgram: "Agregar programa",
    lastResidence: "Última residencia",
    enterLinkedPerson: "Agregar persona vinculada",
    dateDeparture: "Fecha de salida",
    admin: "Administrador",
    canRead: "Puede leer",
    canWrite: "Puede escribir",
    addUser: "Agregar usuario",
    letOut: "Dar salida",
    linkPerson: "Vincular persona",
    bond: "Vínculo",
    addOffender: "Agregar victimario",
    numberOfExternalFilesSimbol: "# expediente",
    noteDeparture: "Nota de salida",
    seePermissions: "Ver permisos",
    requestPermission: "Solicitar permiso",
    removeBond: "Quitar vínculo",
    person_activity_insert_succesful:
      "La actividad de la persona se ha agregado correctamente",
    person_activity_updated_successful:
      "La actividad de la persona se ha actualizado correctamente",
    person_victimizer_insert_succesful:
      "El victimario se ha agregado correctamente",
    person_victimizer_deleted_successful:
      "El victimario se ha eliminado correctamente",
    person_activity_deleted_successful:
      "La actividad se ha eliminado correctamente",
    person_asignation_insert_succesful:
      "El usuario se ha agregado correctamente",
    person_retrieved_successful:
      "La solicitud de acceso se envió correctamente",
    sureDeleteActivity: "¿Está seguro de borrar la actividad?",
    sureDeleteBond: "¿Está seguro de borrar el vínculo?",
    sureDeletePerpetrator: "¿Está seguro de borrar el victimario?",
    person_family_bond_deleted_successful:
      "Se ha eliminado el vínculo de la persona",
    ...administrativeActivitiesLang.es,
  },
};
export default administrativeLang;
