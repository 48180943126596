const executionLang = {
  en: {
    budgetExecution: "Budget execution",
    settlement: "Settlement",
  },
  es: {
    budgetExecution: "Ejecución presupuestaria",
    settlement: "Liquidaciones",
  },
};
export default executionLang;
