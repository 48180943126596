import { RouteRecordRaw } from "vue-router";

const administrativeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/administrative",
    name: "administrative",
    component: () => import("@/views/administrative/index.vue"),
    meta: { requiresAuth: true, permission: "administrativo" },
    children: [
      {
        path: "activities",
        name: "administrative-activities",
        component: () =>
          import("@/views/administrative/activities/index.vue"),
        meta: { requiresAuth: true, permission: "administrativo_actividades" },
      },
    ],
  },
];

export default administrativeRoutes;
