import { RouteRecordRaw } from "vue-router";

const reportRoutes: Array<RouteRecordRaw> = [
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/report/index.vue"),
    meta: { requiresAuth: true, permission: "reportes" },
    children: [
      {
        path: "professional",
        name: "report-professional",
        component: () => import("@/views/report/professional/index.vue"),
        meta: { requiresAuth: true, permission: "reportes_profesional" },
      },
      {
        path: "case-management",
        name: "report-case-management",
        component: () => import("@/views/report/case-management/index.vue"),
        meta: { requiresAuth: true, permission: "reportes_gestion_caso" },
      },
      {
        path: "tematic",
        name: "report-tematic",
        component: () => import("@/views/report/tematic/index.vue"),
        meta: { requiresAuth: true, permission: "reportes_tematica" },
      },
    ],
  },
];

export default reportRoutes;
