const personalLang = {
  en: {
    executionPersonal: "Personal execution",
    dropFileOr: "Drop file or",
    clickToUpload: "Click to upload",
    justValidXls: "Only xls files are accepted",
    execute: "Execute",
    fileNotExecute: "The file cannot be executed",
    associatedMonth: "Associate month",
    associatedItem: "Associate item",
    budgetAssignment: "Budget Assignment",
    planification_expense_request_retrieved_successful:
      "Staff execution succeeded",
  },
  es: {
    executionPersonal: "Ejecución de personal",
    dropFileOr: "Arrastre archivo o",
    clickToUpload: "clic para subir",
    justValidXls: "Solo se aceptan archivos XLS",
    execute: "Ejecutar",
    fileNotExecute: "El archivo no se puede ejecutar",
    associatedMonth: "Mes asociado",
    associatedItem: "Rubro asociado",
    budgetAssignment: "Asinagnación de presupuesto",
    planification_expense_request_retrieved_successful:
      "La ejecución de personal se realizó correctamente",
  },
};
export default personalLang;
