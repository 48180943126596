import { RouteRecordRaw } from "vue-router";

const talentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/talent",
    name: "talent",
    component: () => import("@/views/talent/index.vue"),
    meta: { requiresAuth: true, permission: "talento" },
    children: [
      {
        path: "applicants",
        name: "applicants",
        redirect: {
          name: "applicants-list",
        },
        component: () =>
          import("@/views/talent/applicants/index.vue"),
        meta: { requiresAuth: true, permission: "talento_aplicantes" },
        children: [
          {
            path: "view/:id",
            name: "applicants-view",
            component: () =>
              import("@/views/talent/applicants/viewApplicant.vue"),
            meta: { requiresAuth: true, permission: "talento_aplicantes" },
          },
          {
            path: "list",
            name: "applicants-list",
            component: () =>
              import("@/views/talent/applicants/listApplicant.vue"),
            meta: { requiresAuth: true, permission: "talento_aplicantes" },
          },
        ],
      },
    ],
  },
];

export default talentRoutes;
