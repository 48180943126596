const planningLang = {
  en: {
    planning: "Planning | Planning",
    toPlan: "To plan",
    idPlanning: "ID Planning",
    strategicAxles: "Strategic Axles",
    program: "Program",
    expenseType: "Expense type",
    responsable: "Responsable",
    planner: "Planificador",
    plannedYear: "Año planificado",
    monthlyGoals: "Monthly goals",
    place: "Place",
    addExpense: "Add expense",
    typeExpense: "Expense types",
    amountsExpense: "Amounts",
    user_responsable: "Responsible user",
    planification_insert_error: "Planning could not be saved, please try again",
    planification_insert_succesful: "The planning has saved correctly",
    planification_updated_successful: "The planning has updated correctly",
    planification_expense_deleted_successful:
      "The expense type has deleted correctly",
    planification_expense_updated_successful:
      "The expense type has updated correctly",
    planification_expense_insert_succesful:
      "The expense type has inserted correctly",
    planification_expense_month_insert_successful:
      "The amounts for the expense has saved correctly",
    planification_expense_month_updated_successful:
      "The amounts for the expense has updated correctly",
    sureDeletePlanning: "Are you sure to delete the planning?",
    planification_deleted_successful: "The planning has deleted correctly",
  },
  es: {
    planning: "Planificación | Planificaciones",
    toPlan: "Planificar",
    idPlanning: "ID Planificación",
    strategicAxles: "Eje estratégico",
    program: "Programa",
    expenseType: "Tipo de gasto",
    responsable: "Responsable",
    planner: "Planificador",
    plannedYear: "Año planificado",
    monthlyGoals: "Metas mensuales",
    place: "Plaza",
    addExpense: "Agregar gasto",
    typeExpense: "Tipo de gasto",
    amountsExpense: "Montos",
    user_responsable: "Usuario responsable",
    planification_insert_error:
      "No se pudo guardar la planificación, por favor intente de nuevo",
    planification_insert_succesful:
      "La planificación se ha guardado correctamente",
    planification_updated_successful:
      "La planificación se ha actualizado correctamente",
    planification_expense_deleted_successful:
      "El tipo de gato se ha eliminado correctamente",
    planification_expense_updated_successful:
      "El tipo de gasto se ha actualizado correctamente",
    planification_expense_insert_succesful:
      "El tipo de gasto se ha registrado correctamente",
    planification_expense_month_insert_successful:
      "Lo montos para el gasto se han guardado correctamente",
    planification_expense_month_updated_successful:
      "Lo montos para el gasto se han actualizado correctamente",
    sureDeletePlanning: "¿Esta seguro de borra la planificación?",
    planification_deleted_successful:
      "La planificación se ha eliminado correctamente",
  },
};
export default planningLang;
