import storage from "@/core/plugins/secureStorage";

const PERMISSIONS = "permissions" as string;

/**
 * @description get token form localStorage
 */
export const getPermissions = (): string | null => {
  return storage.getItem(PERMISSIONS);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const savePermissions = (permissions: string): void => {
  storage.setItem(PERMISSIONS, permissions);
};

/**
 * @description remove token form localStorage
 */
export const destroyPermissions = (): void => {
  storage.removeItem(PERMISSIONS);
};

export default { getPermissions, savePermissions, destroyPermissions };
