const headquarterLang = {
  en: {
    headquarter: "Headquarter",
    headquarters: "Headquarters",
    sureDeletedHeadquarter: "Are you sure to delete the headquarter?",
    codeHeadquarter: "Code",
    codeRequiredHeadquarter: "Code is a required field",
    nameHeadquarter: "Name",
    nameRequiredHeadquarter: "Name is a required field",
    descriptionHeadquarter: "Description",
    countryHeadquarter: "Country",
    stateHeadquarter: "State",
    countryRequiredHeadquarter: "Country is a required field",
    headquarters_insert_succesful: "The headquarter has entered correctly",
    headquarters_updated_successful: "The headquarter has updated correctly",
    headquarters_deleted_successful: "The headquarter has been deleted",
    stateHeadquarterRequired: "State is a required field",
    headquarters_departament_insert_succesful:
      "The state has entered correctly",
    headquarters_ubication_insert_succesful:
      "The location has entered correctly",
    headquarters_ubication_deleted_successful:
      "The location has deleted correctly",
    headquarters_ubication_updated_successful:
      "The location has updated correctly",
  },
  es: {
    headquarter: "Sede",
    headquarters: "Sedes",
    sureDeletedHeadquarter: "¿Esta seguro de eliminar la sede?",
    codeHeadquarter: "Código",
    codeRequiredHeadquarter: "Código es un campo requerido",
    nameHeadquarter: "Nombre",
    nameRequiredHeadquarter: "Nombre es un campo requerido",
    descriptionHeadquarter: "Descripción",
    countryHeadquarter: "País",
    stateHeadquarter: "Departamento",
    countryRequiredHeadquarter: "País es un campo requerido",
    headquarters_insert_succesful: "La sede se ha ingresado correctamente",
    headquarters_updated_successful: "La sede se ha  actualizado correctamente",
    headquarters_deleted_successful: "La sede ha sido eliminada",
    stateHeadquarterRequired: "Departamento es un campo requerido",
    headquarters_departament_insert_succesful:
      "El departamento se ha ingresado correctamente",
    headquarters_ubication_insert_succesful:
      "La ubicación se ha ingresado correctamente",
    headquarters_ubication_deleted_successful:
      "La ubicación se ha eliminado correctamente",
    headquarters_ubication_updated_successful:
      "La ubicación se ha actualizado correctamente",
  },
};

export default headquarterLang;
