import { RouteRecordRaw } from "vue-router";

const populationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/population",
    name: "population",
    component: () => import("@/views/population/index.vue"),
    meta: { requiresAuth: true, permission: "poblacion" },
    children: [
      {
        path: "served",
        name: "population-served",
        redirect: {
          name: "population-served-list",
        },
        component: () =>
          import("@/views/population/population-served/index.vue"),
        meta: { requiresAuth: true, permission: "poblacion_poblacion" },
        children: [
          {
            path: "add",
            name: "population-served-add",
            component: () =>
              import("@/views/population/population-served/addPerson.vue"),
            meta: { requiresAuth: true, permission: "poblacion_poblacion" },
          },
          {
            path: "edit/:id",
            name: "population-served-edit",
            component: () =>
              import("@/views/population/population-served/addPerson.vue"),
            meta: { requiresAuth: true, permission: "poblacion_poblacion" },
          },
          {
            path: "list",
            name: "population-served-list",
            component: () =>
              import("@/views/population/population-served/listPerson.vue"),
            meta: { requiresAuth: true, permission: "poblacion_poblacion" },
          },
        ],
      },
      {
        path: "activities",
        name: "population-activities",
        redirect: {
          name: "population-activities-list",
        },
        component: () => import("@/views/population/activities/index.vue"),
        meta: { requiresAuth: true, permission: "poblacion_actividades" },
        children: [
          {
            path: "add",
            name: "population-activities-add",
            component: () =>
              import("@/views/population/activities/form-screen.vue"),
            meta: { requiresAuth: true, permission: "poblacion_actividades" },
          },
          {
            path: "edit/:id",
            name: "population-activities-edit",
            component: () =>
              import("@/views/population/activities/form-screen.vue"),
            meta: { requiresAuth: true, permission: "poblacion_actividades" },
          },
          {
            path: "list",
            name: "population-activities-list",
            component: () =>
              import("@/views/population/activities/list-screen.vue"),
            meta: { requiresAuth: true, permission: "poblacion_actividades" },
          },
        ],
      },
    ],
  },
];

export default populationRoutes;
