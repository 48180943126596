const usersLang = {
  en: {
    users: "Users",
    newUser: "New user",
    updateUser: "Update user",
    responsibility: "Responsibility",
    role: "Role",
    photo: "Photo",
    workgroup: "workgroup",
    user_insert_succesful: "The user has saved correctly",
  },
  es: {
    users: "Usuarios",
    newUser: "Nuevo usuario",
    updateUser: "Actualizar usuario",
    responsibility: "Responsabilidad",
    role: "Rol",
    photo: "Foto",
    workgroup: "Grupo de trabajo",
    user_insert_succesful: "El usuario se ha guardado correctamente",
  },
};

export default usersLang;
