import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert",
  style: {"z-index":"9999999","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.boolTest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " AMBIENTE DE PRUEBAS "))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}