import { RouteRecordRaw } from "vue-router";

const documentationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/documentation",
    name: "documentation",
    component: () => import("@/views/documentation/index.vue"),
    meta: { requiresAuth: true, permission: "documentacion" },
    children: [
      // PROVIDERS
      {
        path: "documentation",
        name: "documentation-documentation",
        redirect: {
          name: "documentation-documentation-list",
        },
        component: () => import("@/views/documentation/documentation/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "documentacion_documentacion",
        },
        children: [
          {
            path: "add",
            name: "documentation-documentation-add",
            component: () =>
              import("@/views/documentation/documentation/formDocumentation.vue"),
            meta: {
              requiresAuth: true,
              permission: "documentacion_documentacion",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "documentation-documentation-edit",
            component: () =>
              import("@/views/documentation/documentation/formDocumentation.vue"),
            meta: {
              requiresAuth: true,
              permission: "documentacion_documentacion",
            },
          },
          {
            path: "view/:id",
            props: true,
            name: "documentation-documentation-view",
            component: () =>
              import("@/views/documentation/documentation/formDocumentationView.vue"),
            meta: {
              requiresAuth: true,
              permission: "documentacion_documentacion",
            },
          },
          {
            path: "list",
            name: "documentation-documentation-list",
            component: () =>
              import("@/views/documentation/documentation/listDocumentation.vue"),
            meta: {
              requiresAuth: true,
              permission: "documentacion_documentacion",
            },
          },
        ],
      },
    ],
  },
];

export default documentationRoutes;
