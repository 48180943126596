const reportLang = {
  en: {
    reportsProfessional: "Report by professional",
    reportsCaseManagement: "Report by case management",
    reportsTematic: "Report by tematic",
    typeDate: "Date type",
    registerDate: "Register date",
    idUser: "User ID",
    quantity: "Quantity",
    idActivity: "Activity ID",
    nameActivity: "Activity",
    stadisticAge: "Stadistic age",
    stadisticActivity: "Stadistic activity",
    stadisticEnters: "Stadistic enters",
    nameHeadquarters: "Headquarters",
    tematicAxis: "Tematic axis",
    nameDepartament: "Departament",
    dateActivity: "Date activity",
    selectPerson: "Select person",
    finalTask: "Final task",
    view: "View",
    completed: "Completed",
    dateAdd: "Date add",
    reports: "Reports",
    professional: "Professional",
  },
  es: {
    reportsProfessional: "Reporte por professional",
    reportsCaseManagement: "Reporte por gestión de caso",
    reportsTematic: "Reporte por temática",
    typeDate: "Tipo de fecha",
    registerDate: "Fecha de registro",
    idUser: "ID de usuario",
    quantity: "Cantidad",
    idActivity: "ID Actividad",
    nameActivity: "Actividad",
    nameHeadquarters: "Sede",
    stadisticAge: "Estadística por edad",
    stadisticActivity: "Estadística por actividad",
    stadisticEnters: "Estadística por ingresos",
    tematicAxis: "Eje temático",
    nameDepartament: "Departamento",
    dateActivity: "Fecha actividad",
    selectPerson: "Seleccione persona",
    finalTask: "Tarea final",
    view: "Ver",
    completed: "Completado",
    dateAdd: "Fecha registro",
    reports: "Reportes",
    professional: "Profesional",
  },
};
export default reportLang;
