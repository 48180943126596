const cooperatingLang = {
  en: {
    addCooperating: "Add cooperating",
    updateCooperating: "Update cooperating",
    billing: "Billing",
    ceiling: "Ceiling",
    addItemCooperating: "Add item",
    updateItemCooperating: "Update item",
    itemsCooperating: "Items",
    newCooperating: "New cooperating",
    unassignedBalance: "Unassigned balance",
    transactions: "Transactions",
    transactionsCooperativeCategory: "Transactions in the cooperative category",
    transactionsInAssignment: "Transactions in assignment",
    typeTransaction: "Type transaction",
    addTransaction: "Add transaction",
    sum: "Sum",
    subtraction: "Subtraction",
    suma: "Sum",
    resta: "Subtraction",
    goalType: "Goal type",
    goal: "Goal",
    addGoal: "Add goal",
    cooperatingGoals: "Cooperating goals",
    confirmDelete: "Are you sure?",
    copyBudgetPolicy: "Copy budget policy",
    startCorrelative: "Start correlative",
    endCorrelative: "Final correlative",
    cooperating_insert_succesful: "The cooperative has saved correctly",
    cooperating_updated_successful: "The cooperative has updated correctly",
    item_cooperating_insert_succesful:
      "The item cooperative has saved correctly",
    item_cooperating_updated_successful:
      "The item cooperative has updated correctly",
  },
  es: {
    addCooperating: "Agregar cooperante",
    updateCooperating: "Actualizar cooperante",
    billing: "Facturación",
    ceiling: "Techo",
    addItemCooperating: "Agregar rubro",
    updateItemCooperating: "Actualizar rubro",
    itemsCooperating: "Rubros",
    newCooperating: "Nuevo cooperante",
    unassignedBalance: "Saldo sin asignar",
    transactions: "Transacciones",
    transactionsCooperativeCategory: "Transacciones en rubro de cooperante",
    transactionsInAssignment: "Transacciones en asignación",
    typeTransaction: "Tipo de transacción",
    addTransaction: "Agregar transacción",
    sum: "Suma",
    subtraction: "Resta",
    suma: "Suma",
    resta: "Resta",
    goalType: "Tipo de meta",
    goal: "Meta",
    addGoal: "Agregar meta",
    cooperatingGoals: "Metas del cooperante",
    confirmDelete: "Está seguro de eliminar?",
    copyBudgetPolicy: "Copiar póliza de presupuesto",
    startCorrelative: "Correlativo inicio",
    endCorrelative: "Correlativo final",
    cooperating_insert_succesful: "El cooperante se ha guardado correctamente",
    cooperating_updated_successful:
      "El cooperante se ha actualizado correctamente",
    item_cooperating_insert_succesful: "El rubro se ha guardado correctamente",
    item_cooperating_updated_successful:
      "El rubro se ha actualizado correctamente",
  },
};

export default cooperatingLang;
