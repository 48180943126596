import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export interface Permission {
  administrator: boolean;
  can_read: boolean;
  can_write: boolean;
}

@Module
export default class PersonModule extends VuexModule {
  idPerson = 0;
  permissions = {} as Permission;

  get currentPerson(): number {
    return this.idPerson;
  }

  get personPermissions(): object {
    return this.permissions;
  }

  get canRead(): boolean {
    return this.permissions.can_read;
  }

  get canWrite(): boolean {
    return this.permissions.can_write;
  }

  get isAdmin(): boolean {
    return this.permissions.administrator;
  }

  @Mutation
  [Mutations.SET_PERSON](expense) {
    this.idPerson = expense;
  }

  @Mutation
  [Mutations.SET_PERMISSION_PERSON](obj: Permission) {
    this.permissions = obj;
  }

  @Action
  [Actions.PERSON](person: number) {
    this.context.commit(Mutations.SET_PERSON, person);
  }

  @Action
  [Actions.PERMISSION_PERSON](person: number) {
    return new Promise<void>((resolve, reject) => {
      ApiService.postAsync(`population/access/${person}`, {}).then(
        ({ data }) => {
          if (data.success) {
            if (data.data) {
              this.context.commit(Mutations.SET_PERMISSION_PERSON, {
                administrator: data.data.administrator === "Y",
                can_read: data.data.can_read === "Y",
                can_write: data.data.can_write === "Y",
              });
            } else {
              this.context.commit(Mutations.SET_PERMISSION_PERSON, {
                administrator: false,
                can_read: false,
                can_write: false,
              });
            }
            resolve();
          } else {
            this.context.commit(Mutations.SET_PERMISSION_PERSON, {
              administrator: false,
              can_read: false,
              can_write: false,
            });
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
          }
        }
      );
    });
  }
}
