import { RouteRecordRaw } from "vue-router";

const executionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/execution",
    name: "execution",
    component: () => import("@/views/execution/index.vue"),
    meta: { requiresAuth: true, permission: "ejecucion" },
    children: [
      {
        path: "requests",
        name: "requests",
        redirect: {
          name: "request-list",
        },
        component: () => import("@/views/execution/request/index.vue"),
        meta: { requiresAuth: true, permission: "ejecucion_solicitudes" },
        children: [
          {
            path: "add",
            name: "request-add",
            component: () => import("@/views/execution/request/newRequest.vue"),
            meta: {
              requiresAuth: true,
              permission: "ejecucion_solicitudes",
            },
            children: [
              {
                path: "purchase-order/:month",
                name: "request-add-purchase-order",
                component: () =>
                  import("@/views/execution/request/PurchaseOrder.vue"),
                meta: {
                  requiresAuth: true,
                  permission: "ejecucion_solicitudes",
                },
              },
              {
                path: "travel-expenses/:month",
                name: "request-add-travel-expenses",
                component: () =>
                  import("@/views/execution/request/TravelExpenses.vue"),
                meta: {
                  requiresAuth: true,
                  permission: "ejecucion_solicitudes",
                },
              },
              {
                path: "petty-cash/:month",
                name: "request-add-petty-cash",
                component: () =>
                  import("@/views/execution/request/PettyCash.vue"),
                meta: {
                  requiresAuth: true,
                  permission: "ejecucion_solicitudes",
                },
              },
            ],
          },
          {
            path: "list",
            name: "request-list",
            component: () =>
              import("@/views/execution/request/requestList.vue"),
            meta: {
              requiresAuth: true,
              permission: "ejecucion_solicitudes",
            },
          },
          {
            path: ":request",
            name: "request-info",
            component: () => import("@/views/execution/request/request.vue"),
            meta: {
              requiresAuth: true,
              permission: "ejecucion_solicitudes",
            },
          },
        ],
      },
      {
        path: "personal",
        name: "personal",
        component: () => import("@/views/execution/personal/index.vue"),
        meta: { requiresAuth: true, permission: "ejecucion_personal" },
      },
    ],
  },
];

export default executionRoutes;
