import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { hasPermission } from "@/core/helpers/functions";
import populationRoutes from "@/router/populationRoutes";
import talentRoutes from "@/router/talentRoutes";
import budgetRoutes from "@/router/budgetRoutes";
import documentationRoutes from "@/router/documentationRoutes";
import executionRoutes from "@/router/executionRoutes";
import settingsRoutes from "@/router/settingsRoutes";
import reportRoutes from "@/router/reportRoutes";
import administrativeRoutes from "@/router/administrativeRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta: { requiresAuth: true },
      },
      ...administrativeRoutes,
      ...populationRoutes,
      ...budgetRoutes,
      ...documentationRoutes,
      ...executionRoutes,
      ...settingsRoutes,
      ...reportRoutes,
      ...talentRoutes,
      // Account
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
            meta: { requiresAuth: true },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "/no-permission",
        name: "no-permission",
        component: () => import("@/views/noPermission.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "sign-in",
    component: () => import("@/components/authentication/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        component: () => import("@/views/authentication/PasswordReset.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/register/:id?",
    name: "register",
    component: () => import("@/views/register/register.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Validate token
  store.dispatch(Actions.VERIFY_AUTH).catch(() => {
    store
      .dispatch(Actions.LOGOUT)
      .then(() => next("/sign-in"))
      .catch(() => next("/sign-in"));
  });

  store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters.isUserAuthenticated
  ) {
    next("/auth");
  } else if (
    !to.matched.some((record) => record.meta.requiresAuth) &&
    store.getters.isUserAuthenticated
  ) {
    next("/dashboard");
  } else if (
    typeof to.meta.permission !== "undefined" &&
    !hasPermission(to.meta.permission as string)
  ) {
    next("/no-permission");
  } else {
    next();
  }
});

router.beforeResolve(() => {
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
});

export default router;
