import { RouteRecordRaw } from "vue-router";

const budgetRoutes: Array<RouteRecordRaw> = [
  {
    path: "/budget",
    name: "budget",
    component: () => import("@/views/budget/index.vue"),
    meta: { requiresAuth: true, permission: "presupuesto" },
    children: [
      {
        path: "planning",
        name: "planning",
        redirect: {
          name: "planning-list",
        },
        component: () => import("@/views/budget/planning/index.vue"),
        meta: { requiresAuth: true, permission: "presupuesto" },
        children: [
          {
            path: "add",
            name: "planning-add",
            component: () => import("@/views/budget/planning/addPlanning.vue"),
            meta: {
              requiresAuth: true,
              permission: "presupuesto_planificacion",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "planning-edit",
            component: () => import("@/views/budget/planning/addPlanning.vue"),
            meta: {
              requiresAuth: true,
              permission: "presupuesto_planificacion",
            },
          },
          {
            path: "list",
            name: "planning-list",
            component: () => import("@/views/budget/planning/listPlanning.vue"),
            meta: {
              requiresAuth: true,
              permission: "presupuesto_planificacion",
            },
          },
        ],
      },
      {
        path: "assignment/:expense",
        name: "assignment",
        component: () => import("@/views/budget/assignment/index.vue"),
        meta: { requiresAuth: true, permission: "asignacion" },
        children: [
          {
            path: "transaction/:cooperatingItem",
            name: "assignment-cooperating-item",
            component: () =>
              import("@/views/budget/assignment/components/Transactions.vue"),
            meta: {
              requiresAuth: true,
              permission: "asignacion_transacciones",
            },
          },
          {
            path: "record/:cooperatingItem",
            name: "assignment-cooperating-item-record",
            component: () =>
              import("@/views/budget/assignment/components/Record.vue"),
            meta: {
              requiresAuth: true,
              permission: "asignacion_historial",
            },
          },
          {
            path: "copy/:cooperatingItem",
            name: "assignment-cooperating-item-copy",
            component: () =>
              import(
                "@/views/budget/assignment/components/CopyCooperating.vue"
              ),
            meta: {
              requiresAuth: true,
              permission: "asignacion",
            },
          },
        ],
      },
    ],
  },
];

export default budgetRoutes;
