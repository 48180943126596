// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig =
  process.env.VUE_APP_LOCAL === "false"
    ? {
        apiKey: "AIzaSyDCIWVgYAaAW5N73DMsZAy7KP2jsRih-eA",
        authDomain: "refugio-de-la-ninez-ca318.firebaseapp.com",
        projectId: "refugio-de-la-ninez-ca318",
        storageBucket: "refugio-de-la-ninez-ca318.appspot.com",
        messagingSenderId: "217205282984",
        appId: "1:217205282984:web:b350ea2d8d550cdb940a9d",
        measurementId: "G-5R8EKTTZ64",
      }
    : {
        apiKey: "AIzaSyDCIWVgYAaAW5N73DMsZAy7KP2jsRih-eA",
        authDomain: "refugio-de-la-ninez-ca318.firebaseapp.com",
        projectId: "refugio-de-la-ninez-ca318",
        storageBucket: "refugio-de-la-ninez-ca318.appspot.com",
        messagingSenderId: "217205282984",
        appId: "1:217205282984:web:b350ea2d8d550cdb940a9d",
        // measurementId: "${config.measurementId}"
      };

export default firebaseConfig;
