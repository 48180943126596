const requestLang = {
  en: {
    request: "Request",
    requests: "Requests",
    myRequests: "My requests",
    newRequest: "New request",
    available: "Available",
    createRequest: "Create request",
    purchaseOrder: "Purchase order",
    reserve: "Reserve",
    pettyCash: "Petty cash",
    executionDate: "Execution date",
    urgent: "Urgent",
    urgentRequest: 'Urgent request',
    travelExpenses: "Travel expenses",
    travelType: "Travel type",
    people: "People",
    dateRequest: "Date request",
    typeRequest: "Type request",
    impressions: "Impressions",
    month: "Month",
    planification_expense_request_insert_succesful:
      "The request has saved correctly",
    planification_expense_request_updated_successful:
      "The request has updated correctly",
    toGo: "To go",
    toReturn: "To return",
    descriptionUrgent: "Description urgent",
    printRequest: "Print purchase request",
    mustGoOrReturn: "Must selected the travel expense if is to go or to return",
    reject: "Reject",
    seeRequest: "See request",
    approve: "Approve",
    sureSaveReserve: "Are you sure to save the reserve?",
    addProvider: "Add providers",
    provider: "Provider",
    providers: "Providers",
    accreditedProvider: "Accredited provider",
    selectProvider: "Select provider",
    providerSelected: "Proveedor seleccionado",
    providerSuggested: "Provider suggested",
    selectProviderSuggested: "Select a provider suggested",
    atLeastOneProvider: "Must add at least one provider",
    descriptionAdmin: "Description admin",
    descriptionPetitioner: "Description petitioner",
    checkNumber: "Check number",
    accountNumber: "Account number",
    check: "Check",
    checkRequest: "Request of check",
    exitPass: "Exit pass",
    comparativeTable: "Comparative table",
    liquidator: "Liquidator",
    vehicle: "Vehicle",
    justificationAdmin: "Admin justification",
    justificationPetitioner: "Justificación de solicitante",
    copyLinkRequest: "Copy link request",
    liquidation: "Liquidation",
    addInvoice: "Add invoice",
    documentType: "Document type",
    emissionDate: "Emission date",
    sureSatisfaction: "Are you satisfied with the service received?",
    billSerie: "Bill serie",
    billNumber: "Bill number",
    receipt: "Receipt",
    invoice: "Invoice",
    invoices: "Invoices",
    document: "Document",
    serie: "Serie",
    number: "Number",
    approveBill: "Approve bill",
    reportSatisfaction: "Satisfaction report",
    sendLiquidation: "Send to liquidation",
    temporalSave: "Temporal save",
    refundInvoices:
      "If you continue, you must make a refund for {0} quetzal(s)",
    warningRejected:
      "At the time of rejecting, all records will be deleted up to the selected state",
    sureReject: "Are you sure you want to decline the request?",
    reasonsRejected: "Reasons for rejection",
    deleteRequest: "Delete request",
    sureDeleteRequest: "Are you sure you want to delete request?",
    planification_expense_request_deleted_successful:
      "The request has deleted correctly",
    approvalLiquidation: "Approval liquidation",
    sureChangeStatus:
      "Are you sure you want to change the status of the request?",
    copyCheckPolicy: "Copy check policy",
    copyLiquidationPolicy: "Copy liquidation policy",
    copyLiquidationCheckPolicy: "Copy liquidation policy check",
    copyPolicyByCheck: "Copy policy by check",
    changeMonth: "Change month",
    sureChangeMonth: "Are you sure you want to change the month?",
    providerInvalid: "Select a valid provider to add the invoice",
    approvals: "Approvals",
    editInvoice: "Edit invoice",
    scheduleExtension: "Schedule extension",
    extensionDate: "Extension date",
    sureExtension: "Are you sure to request the schedule extension?",
    finishEditInvoice: "You need to finish editing the invoice before saving",
    draftDocument: "Document added to draft",
    noExecutionToZero: "The request cannot be settled with value 0",
    reason: "Reason",
    sureApproveExtension: "Are you sure to approve the schedule extension?",
    sureRejectExtension: "Are you sure to reject the schedule extension?",
    approvalUser: "Approval user",
    approvalDate: "Approval date",
    by: "By",
    deliverPhysicalFile: "Deliver physical file",
    transfer: "Transfer",
    transactionNumber: "Transaction number",
    budgetBalances: "Budget balances",
    voucherAvailability: "Voucher availability",
    voucher: "Voucher",
    vouchers: "Vouchers",
    returnVoucher: "Return voucher",
  },
  es: {
    request: "Solicitud",
    requests: "Solicitudes",
    myRequests: "Mis solicitudes",
    newRequest: "Nueva solicitud",
    available: "Disponible",
    createRequest: "Crear solicitud",
    purchaseOrder: "Orden de compra",
    reserve: "Reserva",
    pettyCash: "Caja chica",
    executionDate: "Fecha de ejecución",
    urgent: "Urgente",
    urgentRequest: 'Solicitud urgente',
    travelExpenses: "Viáticos",
    travelType: "Tipo de viaje",
    people: "Personas",
    dateRequest: "Fecha de solicitud",
    typeRequest: "Tipo de solicitud",
    month: "Mes",
    impressions: "Impresiones",
    planification_expense_request_insert_succesful:
      "La solicitud se ha guardado correctamente",
    planification_expense_request_updated_successful:
      "La solicitud se ha actualizado correctamente",
    toGo: "Ida",
    toReturn: "Retorno",
    descriptionUrgent: "Descripción urgente",
    printRequest: "Imprimir solicitud de compra",
    mustGoOrReturn: "Debe seleccionar los viáticos si son de ida o de retorno",
    reject: "Rechazar",
    seeRequest: "Ver solicitud",
    approve: "Aprobar",
    sureSaveReserve: "¿Esta seguro de guardar la reserva?",
    addProvider: "Agregar proveedores",
    provider: "Proveedor",
    providers: "Proveedores",
    accreditedProvider: "Proveedor acreditado",
    selectProvider: "Seleccione proveedor",
    providerSelected: "Proveedor seleccionado",
    providerSuggested: "Proveedor sugerido",
    selectProviderSuggested: "Seleccione un proveedor sugerido",
    atLeastOneProvider: "Debe agregar al menos un proveedor",
    descriptionAdmin: "Descripción de administración",
    descriptionPetitioner: "Descripción de solicitante",
    checkNumber: "Número de cheque",
    accountNumber: "Número de cuenta",
    check: "Cheque",
    checkRequest: "Solicitud de cheque",
    exitPass: "Pase de salida",
    comparativeTable: "Cuadro comparativo",
    liquidator: "Usuario liquidante",
    vehicle: "Vehículo",
    justificationAdmin: "Justificación de administración",
    justificationPetitioner: "Justificación de solicitante",
    copyLinkRequest: "Copiar enlace de solicitud",
    liquidation: "Liquidación",
    addInvoice: "Agregar factura",
    documentType: "Tipo de documento",
    emissionDate: "Fecha de emisión",
    billSerie: "Serie de factura",
    billNumber: "Número de factura",
    receipt: "Recibo",
    invoice: "Factura",
    invoices: "Facturas",
    document: "Documento",
    serie: "Serie",
    number: "Número",
    approveBill: "Aprobar facturas",
    reportSatisfaction: "Informe de satisfacción",
    sendLiquidation: "Enviar a liquidación",
    temporalSave: "Guardar temporal",
    sureSatisfaction: "¿Está satisfecho con el servicio recibido?",
    refundInvoices:
      "Si continua, debe de hacer un reembolso por {0} quetzal(es)",
    warningRejected:
      "Al momento de rechazar se borrarán todos los registros hasta el estado seleccionado",
    sureReject: "¿Seguro que desea rechazar la solicitud?",
    reasonsRejected: "Motivos de rechazo",
    deleteRequest: "Eliminar solicitud",
    sureDeleteRequest: "¿Seguro que desea eliminar la solicitud?",
    planification_expense_request_deleted_successful:
      "La solicitud se ha eliminado correctamente",
    approvalLiquidation: "Aprobar liquidación",
    sureChangeStatus: "¿Seguro que desea cambiar de estado la solicitud?",
    copyCheckPolicy: "Copiar póliza de cheque",
    copyPolicyByCheck: "Copia póliza por cheque",
    copyLiquidationPolicy: "Copiar póliza de liquidación",
    copyLiquidationCheckPolicy: "Copiar póliza de liquidación cheque",
    changeMonth: "Cambiar mes",
    sureChangeMonth: "¿Seguro que desea cambiar de mes la solicitud?",
    providerInvalid: "Seleccione un proveedor válido para agregar la factura",
    approvals: "Aprobaciones",
    editInvoice: "Editar factura",
    scheduleExtension: "Extensión de horario",
    extensionDate: "Fecha de extensión",
    sureExtension: "Está seguro de solicitar la extensión de horario",
    finishEditInvoice:
      "Es necesario terminar de editar la factura antes de guardar",
    draftDocument: "El documento se agregó al borrador",
    noExecutionToZero: "La solicitud no se puede liquidar con valor 0",
    reason: "Razón",
    sureApproveExtension: "Está seguro de aprobar la extensión de horario?",
    sureRejectExtension: "Está seguro de rechazar la extensión de horario?",
    approvalUser: "Usuario aprobación",
    approvalDate: "Fecha aprobación",
    by: "Por",
    deliverPhysicalFile: "Entrega papelería física",
    transfer: "Transferencia",
    transactionNumber: "Número de transacción",
    budgetBalances: "Disponibilidad presupuestaria",
    voucherAvailability: "Disponibilidad de vales",
    voucher: "Vale",
    vouchers: "Vales",
    returnVoucher: "Regresar vale",
  },
};
export default requestLang;
