import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class AssignmentModule extends VuexModule {
  expenseID = 0;

  get currentExpense(): number {
    return this.expenseID;
  }

  @Mutation
  [Mutations.SET_EXPENSE](expense) {
    this.expenseID = expense;
  }

  @Action
  [Actions.EXPENSE](expense) {
    this.context.commit(Mutations.SET_EXPENSE, expense);
  }
}
