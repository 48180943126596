import { RouteRecordRaw } from "vue-router";

const settingsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/configuration",
    name: "configuration",
    component: () => import("@/views/configuration/index.vue"),
    meta: { requiresAuth: true, permission: "configuracion" },
    children: [
      // Headquarters
      {
        path: "headquarter",
        name: "configuration-headquarter",
        redirect: {
          name: "configuration-headquarter-list",
        },
        component: () =>
          import("@/views/configuration/headquarters/headquarters.vue"),
        meta: { requiresAuth: true, permission: "configuracion_sede" },
        children: [
          {
            path: "add",
            name: "configuration-headquarter-add",
            component: () =>
              import("@/views/configuration/headquarters/addHeadquarter.vue"),
            meta: { requiresAuth: true, permission: "configuracion_sede" },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-headquarter-edit",
            component: () =>
              import("@/views/configuration/headquarters/addHeadquarter.vue"),
            meta: { requiresAuth: true, permission: "configuracion_sede" },
          },
          {
            path: "list",
            name: "configuration-headquarter-list",
            component: () =>
              import("@/views/configuration/headquarters/listHeadquarter.vue"),
            meta: { requiresAuth: true, permission: "configuracion_sede" },
          },
        ],
      },
      // Cooperating
      {
        path: "cooperating",
        name: "configuration-cooperating",
        redirect: {
          name: "configuration-cooperating-list",
        },
        component: () => import("@/views/configuration/cooperating/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_cooperantes",
        },
        children: [
          {
            path: "add",
            name: "configuration-cooperating-add",
            component: () =>
              import("@/views/configuration/cooperating/form.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-cooperating-edit",
            component: () =>
              import("@/views/configuration/cooperating/form.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
          {
            path: "list",
            name: "configuration-cooperating-list",
            component: () =>
              import("@/views/configuration/cooperating/list.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
          {
            path: ":id/items",
            name: "configuration-cooperating-items",
            component: () =>
              import("@/views/configuration/cooperating/listItem.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
          {
            path: ":id/item/add",
            name: "configuration-cooperating-item-add",
            component: () =>
              import("@/views/configuration/cooperating/formItem.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
          {
            path: ":id/item/edit/:item",
            name: "configuration-cooperating-item-edit",
            component: () =>
              import("@/views/configuration/cooperating/formItem.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_cooperantes",
            },
          },
        ],
      },
      // USERS
      {
        path: "users",
        name: "configuration-users",
        redirect: {
          name: "configuration-users-list",
        },
        component: () => import("@/views/configuration/users/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_usuarios",
        },
        children: [
          {
            path: "add",
            name: "configuration-users-add",
            component: () =>
              import("@/views/configuration/users/formUsers.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_usuarios",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-users-edit",
            component: () =>
              import("@/views/configuration/users/formUsers.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_usuarios",
            },
          },
          {
            path: "list",
            name: "configuration-users-list",
            component: () =>
              import("@/views/configuration/users/listUsers.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_usuarios",
            },
          },
        ],
      },
      // ROLES
      {
        path: "roles",
        name: "configuration-roles",
        redirect: {
          name: "configuration-roles-list",
        },
        component: () => import("@/views/configuration/roles/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_roles",
        },
        children: [
          {
            path: "add",
            name: "configuration-roles-add",
            component: () =>
              import("@/views/configuration/roles/formRoles.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_roles",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-roles-edit",
            component: () =>
              import("@/views/configuration/roles/formRoles.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_roles",
            },
          },
          {
            path: "list",
            name: "configuration-roles-list",
            component: () =>
              import("@/views/configuration/roles/listRoles.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_roles",
            },
          },
        ],
      },
      // PROVIDERS
      {
        path: "providers",
        name: "configuration-providers",
        redirect: {
          name: "configuration-providers-list",
        },
        component: () => import("@/views/configuration/providers/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_proveedores",
        },
        children: [
          {
            path: "add",
            name: "configuration-providers-add",
            component: () =>
              import("@/views/configuration/providers/formProviders.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_proveedores",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-providers-edit",
            component: () =>
              import("@/views/configuration/providers/formProviders.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_proveedores",
            },
          },
          {
            path: "list",
            name: "configuration-providers-list",
            component: () =>
              import("@/views/configuration/providers/listProviders.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_proveedores",
            },
          },
        ],
      },
      // CATALOGS
      {
        path: "catalogs",
        name: "configuration-catalogs",
        component: () => import("@/views/configuration/catalogs/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_catalogos",
        },
      },
      // VOUCHERS
      {
        path: "vouchers",
        name: "configuration-vouchers",
        redirect: {
          name: "configuration-vouchers-list",
        },
        component: () => import("@/views/configuration/vouchers/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "configuracion_vales",
        },
        children: [
          {
            path: "add",
            name: "configuration-vales-add",
            component: () =>
                import("@/views/configuration/vouchers/components/formVouchers.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_vales",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "configuration-vouchers-edit",
            component: () =>
                import("@/views/configuration/vouchers/components/formVouchers.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_vales",
            },
          },
          {
            path: "list",
            name: "configuration-vouchers-list",
            component: () =>
                import("@/views/configuration/vouchers/components/voucher.vue"),
            meta: {
              requiresAuth: true,
              permission: "configuracion_vales",
            },
          },
        ],
      },
    ],
  },
];

export default settingsRoutes;
