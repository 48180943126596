const assignmentLang = {
  en: {
    assignment: "Assignment",
    transaction: "Transaction | Transactions",
    record: "Record",
    plannedAmount: "Planned amount",
    availableBalance: "Available item",
    availableBalanceItemMonth: "Available for execution",
    transactionType: "Transaction type",
    debit: "Minus",
    payment: "Plus",
    amount: "Amount",
    transactionHistory: "Transaction history",
    petitioner: "Petitioner",
    idRequest: "ID Request",
    idTransaction: "ID Transaction",
    planned: "Planned",
    assigned: "Assigned",
    cooperating: "Cooperating",
    itemCooperating: "Item",
    copyCooperating: "Copy cooperating",
    planification_expense_month_item_cooperating_insert_succesful:
      "The item cooperanting has saved correctly",
    planification_expense_month_item_cooperating_transaction_insert_succesful:
      "The transaction has saved correctly",
    planification_expense_month_item_cooperating_updated_successful:
      "The transaction has updated correctly",
    planification_expense_month_item_cooperating_deleted_successful:
      "The transaction has deleted correctly",
    pendingPurchaseRequest: "Pending purchase request",
  },
  es: {
    assignment: "Asignación",
    transaction: "Transacción | Transacciones",
    record: "Historial",
    planningAmount: "Monto planificado",
    availableBalance: "Disponible rubro",
    availableBalanceItemMonth: "Disponible para ejecución",
    transactionType: "Tipo de transacción",
    debit: "Resta",
    pay: "Suma",
    amount: "Monto",
    transactionHistory: "Historial de transacciones",
    petitioner: "Solicitante",
    idRequest: "ID Solicitud",
    idTransaction: "ID Transacción",
    planned: "Planificado",
    assigned: "Asignado",
    cooperating: "Cooperantes",
    itemCooperating: "Rubro",
    copyCooperating: "Copiar cooperante",
    planification_expense_month_item_cooperating_insert_succesful:
      "El rubro del cooperante se ha guardado correctamente",
    planification_expense_month_item_cooperating_transaction_insert_succesful:
      "La transacción se ha guardado correctamente",
    planification_expense_month_item_cooperating_updated_successful:
      "La transacción se ha actualizado correctamente",
    planification_expense_month_item_cooperating_deleted_successful:
      "La transacción se ha eliminado correctamente",
    pendingPurchaseRequest: "Solicitud de compras pendientes",
  },
};
export default assignmentLang;
