import storage from "@/core/plugins/secureStorage";

const USER_INFO = "user_info" as string;

/**
 * @description get token form localStorage
 */
export const getUser = (): string | null => {
  return storage.getItem(USER_INFO);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveUser = (user: string): void => {
  storage.setItem(USER_INFO, user);
};

/**
 * @description remove token form localStorage
 */
export const destroyUser = (): void => {
  storage.removeItem(USER_INFO);
};

export default { getUser, saveUser, destroyUser };
