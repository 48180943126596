import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/user/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import PermisionService from "@/core/services/user/PermisionService";

export interface User {
  id_user: string;
  cname: string;
  cuser: string;
  email: string;
  password: string;
  telephone: string;
  token: string;
  nit: string;
}

export interface Permissions {
  slug: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  permission: Array<Permissions> = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    try {
      return JSON.parse(UserService.getUser() || "");
    } catch (e) {
      return e.toString();
    }
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    if (this.user.token) {
      JwtService.saveToken(this.user.token);
    }
    UserService.saveUser(JSON.stringify(user));
    user.permisos.forEach((val) => {
      this.permission.push(val);
    });

    PermisionService.savePermissions(JSON.stringify(this.permission));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    UserService.destroyUser();
    JwtService.destroyToken();
    PermisionService.destroyPermissions();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response);
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    ApiService.setHeader();
    this.context.commit(Mutations.PURGE_AUTH);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("logout", {})
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot", payload)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response);
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](values) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("reset", values)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response);
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("user-check", {})
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(({ data }) => {})
        .catch(() => {
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
}
