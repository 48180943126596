const ticketsLang = {
  en: {
    support: "Support",
    addTicket: "Add ticket",
    myTickets: "My tickets",
    noTicket: "No. ticket",
    priority: "Priority",
    criticality: "Criticality",
    descriptionTicketRequired: "The description is required to save ticket",
    ticketNumber: "Ticket #{0}",
    typeTicket: "Type",
    statusTicket: {
      undefined: "",
      generado: "Generated",
      asignado: "Assigned",
      curso: "In progress",
      pendiente: "Pending",
      resuelto: "Resolved",
      cancelado: "Cancelled",
      finalizado: "Finalized",
      rechazado: "Rejected",
    },
    assignedTo: "Assigned to",
    insertDate: "Date of admission",
    estimateDateTicket: "Estimated date of completion",
    followUp: "Follow-up",
    commentRequired: "The comment is a required field",
    levels: {
      alta: "High",
      media: "Normal",
      baja: "Low",
    },
    createTicket: "Add new ticket",
    createFollowupTicket: "Create follow-up ticket",
    followupTo: "Follow-up to ticket #{0}",
    canSaveFile: "The document could not be saved",
    clickToUpload: "Click to upload",
    seeTicket: "Ver ticket",
  },
  es: {
    support: "Soporte",
    addTicket: "Agregar ticket",
    priority: "Prioridad",
    criticality: "Criticidad",
    myTickets: "Mis tickets",
    descriptionTicketRequired:
      "La descripción es requerida para guardar ticket",
    ticketNumber: "Ticket #{0}",
    noTicket: "No. ticket",
    typeTicket: "Tipo",
    statusTicket: {
      undefined: "",
      generado: "Generado",
      asignado: "Asignado",
      curso: "Curso",
      pendiente: "Pendiente",
      resuelto: "Resuelto",
      cancelado: "Cancelado",
      finalizado: "Finalizado",
      rechazado: "Rechazado",
    },
    assignedTo: "Asignado",
    insertDate: "Fecha de ingreso",
    estimateDateTicket: "Fecha estimada de finalización",
    followUp: "Seguimiento",
    commentRequired: "El comentario es un campo requerido",
    levels: {
      alta: "Alta",
      media: "Media",
      baja: "Baja",
    },
    createTicket: "Agregar nuevo ticket",
    createFollowupTicket: "Crear ticket de seguimiento",
    followupTo: "Seguimiento a ticket #{0}",
    canSaveFile: "No se pudo guardar el documento",
    clickToUpload: "Subir archivo",
    seeTicket: "Ver ticket",
  },
};
export default ticketsLang;
