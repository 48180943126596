const talentLang = {
  en: {
    placeApplicant: "Place applicant",
    departamentAplicant: "Department applicant",
    firstName: "First name",
    secondName: "Second name",
    thirdName: "Third name",
    paternalLastname: "Paternal lastname",
    maternalLastname: "Maternal lastname",
    marriedName: "Married name",
    personalEmail: "Personal e-mail",
    vocationalInformation: "Vocational information",
    workInformation: "Work information",
    familyReferences: "Family references",
    supportDocumentation: "Support documentation",
    back: "Back",
    continue: "Continue",
    birthday: "Birthday",
    gender: "Gender",
    extended: "Extended",
    dateExpireDpi: "Date expire DPI",
    profession: "Profession",
    collegiateNumber: "Collegiate number",
    dateExpireCollegiate: "Date expire collegiate",
    countryResidence: "Country residence",
    departamentResidence: "Departament residence",
    cityResidence: "City residence",
    homePhone: "Home phone",
    home_phone: "Home phone",
    personalPhone: "Personal phone",
    licenses: "Licenses",
    licenseNumber: "License number",
    licenseType: "License type",
    studies: "Studies",
    gradeObtained: "Grade obtained",
    degreeYear: "Year",
    titleDegree: "Title",
    centerName: "Center name",
    levelWritten: "Level written",
    levelSpoken: "Level spoken",
    works: "Works",
    companyName: "Company name",
    position: "Position",
    otherCharges: "Other charges",
    nameReference: "Name reference",
    relationship: "Relationship",
    ageReference: "Age",
    emergencyContact: "Emergency contact",
    nameAttachment: "Name attachment",
    basic: "Basic",
    middle: "Middle",
    advanced: "Advanced",
    native: "Native",
    dateEntry: "Date entry",
    companyPhone: "Company phone",
    startingSalary: "Starting salary",
    reasonExit: "Reason exit",
    companyContribution: "What was your biggest contribution to the company?",
    dateOut: "Date out",
    dependents: "Dependents",
    finalSalary: "Final salary",
    reasonExitDescription: "Reason exit description",
    companyLike: "What did you like most about the company?",
    functions: "Functions",
    immediateBoss: "Immediate boss",
    referencesRequest: "Can references be requested?",
    availability: "Availavility",
    companyDislike: "What did you like least about the company?",
    resignation: "Resignation",
    dismissal: "Dismissal",
    other: "Other",
    immediate: "Immediate",
    less_week: "Lees week",
    week: "Week",
    send: "Send",
    fifteen_days: "Fifteen days",
    more_fifteen_days: "More fifteen days",
    workPlace: "Work place",
    phoneReference: "Phone",
    professionReference: "Profession",
    database: "Database",
    note: "Note",
    identification_document: "Identification document",
    extended_in: "Extended in",
    sendMail: "Send mail",
    applicant: "Applicant",
    applicantModified: "Modified by applicant",
    institutional_connections: "Instutional connections",
    knows_someone_at_rdn: "Knows someone at RDN?",
    seen: "Seen",
    considerate: "To considerate",
    hired: "Hired",
    discharged: "Discharged",
    idApplicant: "Applicant ID",
    changeStatus: "Change status",
    talent: "Human talent",
    applicants: "Applicants",
    statusChanges: "Status changes",
    mail_send: "Mail send",
    info_insert_succesful:
      "The information has been added successfully",
    sureSave:
      "Are you sure to submit the information?",
    info_updated_successful:
      "The information has been updated successfully",
    info_send_application_failure:
      "Your application has not been sent because there is another application with the same information",
  },
  es: {
    placeApplicant: "Plaza aplicación",
    departamentAplicant: "Departamento aplicación",
    firstName: "Primer nombre",
    secondName: "Segundo nombre",
    thirdName: "Tercer nombre",
    paternalLastname: "Apellido paterno",
    maternalLastname: "Apellido materno",
    marriedName: "Apellido de casada",
    personalEmail: "Correo electrónico personal",
    vocationalInformation: "Información vocacional",
    workInformation: "Información laboral",
    familyReferences: "Referencias familiares",
    supportDocumentation: "Documentación de soporte",
    back: "Atrás",
    continue: "Continuar",
    birthday: "Fecha de nacimiento",
    gender: "Género",
    extended: "Extendido",
    dateExpireDpi: "Fecha expiración DPI",
    profession: "Profesión",
    collegiateNumber: "Número colegiado",
    dateExpireCollegiate: "Fecha expiración número colegiado",
    countryResidence: "País residencia",
    departamentResidence: "Departamento residencia",
    cityResidence: "Municipio residencia",
    homePhone: "Teléfono casa",
    home_phone: "Teléfono casa",
    personalPhone: "Teléfono personal",
    licenses: "Licencias",
    licenseNumber: "Número licencia",
    licenseType: "Tipo licencia",
    studies: "Estudios",
    gradeObtained: "Grado obtenido",
    degreeYear: "Año",
    titleDegree: "Título",
    centerName: "Nombre centro",
    levelWritten: "Nivel escritura",
    levelSpoken: "Nivel hablado",
    works: "Trabajos",
    companyName: "Nombre empresa",
    position: "Puesto",
    otherCharges: "Otros cargos",
    nameReference: "Nombre referencia",
    relationship: "Relación",
    ageReference: "Edad",
    emergencyContact: "Contacto emergencia",
    nameAttachment: "Nombre adjunto",
    basic: "Básico",
    middle: "Intermedio",
    advanced: "Avanzado",
    native: "Nativo",
    dateEntry: "Fecha ingreso",
    companyPhone: "Teléfono",
    startingSalary: "Salario inicial",
    reasonExit: "Motivo salida",
    companyContribution: "¿Cuál fue su mayor aporte a la empresa?",
    dateOut: "Fecha salida",
    dependents: "Personas a cargo",
    finalSalary: "Salario final",
    reasonExitDescription: "Descripción motivo salida",
    companyLike: "Lo que más le agradaba de la empresa",
    functions: "Funciones",
    immediateBoss: "Jefe inmediato",
    referencesRequest: "¿Se pueden solicitar referencias?",
    availability: "Disponibilidad",
    companyDislike: "Lo que menos le agradaba de la empresa",
    resignation: "Renuncia",
    dismissal: "Despido",
    other: "Otro",
    immediate: "Inmediato",
    less_week: "Menos de una semana",
    week: "Semana",
    send: "Enviar",
    fifteen_days: "15 días",
    more_fifteen_days: "Más de 15 días",
    workPlace: "Lugar de trabajo",
    phoneReference: "Teléfono",
    professionReference: "Profesión",
    database: "Base de datos",
    note: "Nota",
    identification_document: "Documento de identificación",
    extended_in: "Extendido en",
    sendMail: "Enviar correo",
    applicant: "Aplicante",
    applicantModified: "Modificado por aplicante",
    institutional_connections: "Conexiones institucionales",
    knows_someone_at_rdn: "¿Conoce a alguien en RDN?",
    seen: "Visto",
    considerate: "A considerar",
    hired: "Contratado",
    discharged: "De baja",
    idApplicant: "ID aplicante",
    changeStatus: "Cambiar estado",
    talent: "Talento humano",
    applicants: "Aplicantes",
    statusChanges: "Cambios de estado",
    mail_send: "Correo enviado",
    info_insert_succesful:
        "La información ha sido agregada exitosamente",
    sureSave:
        "¿Está seguro de enviar la información?",
    info_updated_successful:
        "La información ha sido actualizada exitosamente",
    info_send_application_failure:
        "Su solicitud no ha sido enviada porque existe otra solicitud con la misma información",
  },
};
export default talentLang;
