const providersLang = {
  en: {
    hasRetention: "Subject to retention",
    country: "Country",
    department: "Department",
    city: "City",
    provider_insert_succesful: "The provider has saved correctly",
    provider_updated_successful: "The provider has updated correctly",
  },
  es: {
    hasRetention: "Sujeto a retención",
    country: "País",
    department: "Departamento",
    city: "Ciudad",
    provider_insert_succesful: "El proveedor se ha guardado correctamente",
    provider_updated_successful: "El proveedor se ha actualizado correctamente",
  },
};

export default providersLang;
